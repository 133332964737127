<template>
   <footer class="iq-footer">
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
               <div class="player row">
                  <div class="details col-6 col-sm-4 col-md-4 col-lg-4" >
                     <div class="now-playing"></div>
                     <!-- <div class="track-art"></div> -->
                     <img src="audio.img" class="track-art" alt="">
                     <div>
                        <div class="track-name">desc_ar </div>
                        <div class="track-artist">title_ar</div>
                     </div>
                  </div>
                  <div class="slider_container slider_music col-sm-5 col-md-4 col-lg-4">
                     <div class="current-time">timer</div>
                     <!-- <input type="range" class="seek_slider" @click="seek($event)" ref="progress"> -->
                      <div class="progress" @click="seek($event)" ref="progress">            
                       <div class="progress-bar bg-success " role="progressbar" style="width: 0%;" :style="{'width' : step + '%'}"></div>            
                      </div>
                     <div class="total-duration">{{duration}}</div>
                  </div>
                  <div class="buttons col-6  col-sm-3 col-md-2  col-lg-2">
                     <div class="prev-track" @click="prevButton ? previous() : ''"><i class="fa fa-step-backward fa-2x"></i></div>
                     <div class="playpause-track">
                        <i class="fa fa-play-circle fa-3x" v-if="!pauseTrack" @click="play()"></i>
                        <i class="fa fa-pause-circle fa-3x" v-else @click="pause()"></i>
                      </div>
                     <div class="next-track" @click="nextButton ? next() : ''"><i class="fa fa-step-forward fa-2x"></i></div>
                  </div>
                  <div class="slider_container sound col-sm-6 col-md-2  col-lg-2">
                     <i class="fa fa-volume-down" ></i>
                     <input type="range" class="volume_slider" @click="volume($event)" ref="volBar">
                     <i class="fa fa-volume-up"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
</template>
<script>
import  $ from 'jquery'
export default {
    mounted(){
     
    },
    setup() {
        
    },
}
</script>
<style scoped>




   .progress{
  min-width: 250px;
  height: 8px;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  /* background: rgba(0,0,0,0.06) */
}
 .progress-bar{
  opacity: 0.8;
}
</style>
